.logo-container {
    z-index: 0;
    width: 500px;
    height: 609px;
    opacity: 0;
    animation: bounceIn 1s 3s;
    animation-fill-mode: forwards;
    position: absolute;
    top: 0;
    right: 15%;
    bottom: 0;
    left: auto;
    margin: auto;
  
    .solid-logo {
      position: absolute;
      top: auto;
      right: auto;
      bottom: auto;
      left: 0;
      margin: auto;
      width: 100%;
      transform: rotateZ(15deg) !important;
      z-index: 1;
    }
  }
